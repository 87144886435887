/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import "./App.scss";
import { Container, Row, Card, Col } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import logo from "./logo.png";

function App() {
  const { t } = useTranslation();
  function parse_query_string(query: string) {
    const vars = query.split("?");
    const query_string: any = {};
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        const arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
    return query_string;
  }

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  return (
    <Container className="App">
      <Card>
        <Card.Body>
          <img src={logo} alt="" />
          <Formik
            initialValues={{
              email: "",
              name: "",
              idnumber: "",
              tel: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Email is invalid")
                .required("Email is required"),
              name: Yup.string().required("Name is required"),
              idnumber: Yup.string()
                .required("ID Number is required")
                .min(8, "too short")
                .max(12, "too long"),
              tel: Yup.string()
                .required("Tel is required")
                .matches(phoneRegExp, "Phone number is not valid")
                .min(10, "too short")
                .max(10, "too long"),
            })}
            onSubmit={(fields) => {
              const query_string = window.location.search;
              const parsed_qs = parse_query_string(query_string);
              const requestOptions = {
                method: "POST",
              };

              fetch(
                `https://travelins.ajjawi.com/backoffice/api/led?name=${fields.name}&id_number=${fields.idnumber}&email=${fields.email}&tel=${fields.tel}&agentID=${parsed_qs.agent}`,

                requestOptions
              )
                .then((response) => response.text())
                .then((result) => alert(result))
                .catch((error) => alert(error));
            }}
            render={({ errors, touched }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="name">{t("name")}</label>
                  <Field
                    name="name"
                    type="text"
                    className={
                      "form-control" +
                      (errors.name && touched.name ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">{t("userIDNumber")}</label>
                  <Field
                    name="idnumber"
                    type="text"
                    className={
                      "form-control" +
                      (errors.idnumber && touched.idnumber ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="idnumber"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">{t("email")}</label>
                  <Field
                    name="email"
                    type="text"
                    className={
                      "form-control" +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">{t("tel")}</label>
                  <Field
                    name="tel"
                    type="text"
                    className={
                      "form-control" +
                      (errors.tel && touched.tel ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="tel"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary mr-2">
                    {t("submit")}
                  </button>
                </div>
              </Form>
            )}
          />
        </Card.Body>
      </Card>
    </Container>
  );
}

export default App;
